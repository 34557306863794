import urls from '#/hr/js/config/api.js';
const { job } = urls;

export default {
    // 更新HR职位详情（描述）
    updateHRJobDescription(params) {
        return window._request({
            url: job.updateHRJobDescription,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    
    // HR审批职位
    getApplyJobList(params) {
        return window._request({
            url: job.applyJobList,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    // HR职位(全部职位)
    getJobList(params) {
        return window._request({
            url: job.jobList,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    companyJobList(params) {
        return window._request({
            url: job.companyJobList,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    
    
    // HR职位推面o数据
    getJobStatisticInfo(params) {
        return window._request({
            url: job.getJobStatisticInfo,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    
    // HR职位详情
    getHRJobDetail(jobId) {
        return window._request({
            url: job.jobDetail.replace(/\%p/ig, jobId),
            method: "GET",
            baseURL: "LbdOpenApi",
        });
    },
    // HR审批职位详情
    getHRJobApplyDetail(applicationId) {
        return window._request({
            url: job.jobApplyDetail.replace(/\%p/ig, applicationId),
            method: "GET",
            baseURL: "LbdOpenApi",
        });
    },
    // HR职位推荐人选列表
    getHRJobRecommendedCandidates(jobId) {
        return window._request({
            url: job.jobRecommendedCandidates.replace(/\%p/ig, jobId),
            method: "GET",
            baseURL: "LbdOpenApi",
        });
    },
};