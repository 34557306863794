var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "company-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "company-top",
      },
      [
        _c("div", { staticClass: "company-top-l" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.companyInfo.shortName) + "\n                "),
            _c("span", { staticClass: "tag" }, [_vm._v("已认证")]),
          ]),
          _c(
            "div",
            {
              staticClass: "sub-title",
              domProps: { textContent: _vm._s(_vm.companyInfo.name) },
            },
            [_vm._v("赛诺菲国际医药集团")]
          ),
          _c("div", {
            staticClass: "content",
            domProps: {
              textContent: _vm._s(_vm.companyInfo.description || "暂无"),
            },
          }),
        ]),
        _c("div", { staticClass: "company-top-r" }),
      ]
    ),
    _c("div", { staticClass: "company-main" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "company-l",
        },
        [
          _c(
            "div",
            {
              staticClass: "title-bar",
              staticStyle: { "margin-bottom": "20px" },
            },
            [_vm._v("\n                招聘岗位\n            ")]
          ),
          _c("customer-job-list", {
            ref: "customerJobList",
            attrs: { jobList: _vm.dataList, total: _vm.dataListTotal },
            on: {
              "update-list": function ($event) {
                return _vm.getNormalJobList(true)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "company-r",
        },
        [
          _c("div", { staticClass: "title-bar" }, [
            _vm._v("\n                公司信息\n            "),
          ]),
          _c(
            "div",
            { staticClass: "info-item", staticStyle: { "margin-top": "20px" } },
            [
              _c("i", {
                staticClass: "el-icon-price-tag customer-info-icon",
                attrs: { title: "行业" },
              }),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.companyInfo.industryText || "无"),
                  },
                },
                [_vm._v("互联网/电子商务")]
              ),
            ]
          ),
          _c("div", { staticClass: "info-item" }, [
            _c("i", {
              staticClass: "el-icon-user customer-info-icon",
              attrs: { title: "规模" },
            }),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.formatScale(_vm.companyInfo.companyScale)
                  ),
                },
              },
              [_vm._v("50-150人 ")]
            ),
          ]),
          _c("div", { staticClass: "info-item" }, [
            _c("i", {
              staticClass: "el-icon-location-outline customer-info-icon",
              attrs: { title: "地址" },
            }),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.companyInfo.address || "暂无公司地址"
                  ),
                },
              },
              [_vm._v("深圳南山区科技园高新技术产业园R1-B座2楼202")]
            ),
          ]),
          _c("div", { staticClass: "info-item" }, [
            _c("i", {
              staticClass: "el-icon-office-building customer-info-icon",
              attrs: { title: "网址" },
            }),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.companyInfo.webSite || "暂无公司网址"
                  ),
                },
              },
              [_vm._v("暂无公司网址 ")]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }