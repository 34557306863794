var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-job-list" },
    [
      _vm.jobList.length > 0
        ? _vm._l(_vm.jobList, function (job, index) {
            return _c("div", { key: index, staticClass: "customer-job-item" }, [
              _c("div", { staticClass: "job-item-header" }, [
                _c("div", { staticClass: "job-title" }, [
                  _c(
                    "a",
                    {
                      staticClass: "job-title-link text-ellipsis",
                      attrs: {
                        target: "_blank",
                        title: job.name,
                        href: `/#/Job/${job.id}`,
                      },
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(job.name) +
                          "\n              "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "job-location text-ellipsis",
                      attrs: { title: job.locationText },
                    },
                    [
                      _vm._v(
                        "\n                  [" +
                          _vm._s(job.locationText) +
                          "]\n              "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "job-detail-tags" },
                    [
                      _vm._l(job.activityTags, function (item) {
                        return _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.id !== "StandstillJob" &&
                                  item.id != "FirstOrderActivityJob",
                                expression:
                                  "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                              },
                            ],
                            key: item.id,
                            attrs: {
                              effect: "light",
                              placement: "right",
                              disabled: !item.description,
                              "popper-class": "job-tag-description",
                            },
                          },
                          [
                            _c("div", {
                              attrs: { slot: "content" },
                              domProps: { innerHTML: _vm._s(item.description) },
                              slot: "content",
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "job-detail-tag",
                                class: `job-tag-${
                                  _vm.jobActivityTag[item.id].theme
                                }`,
                                attrs: { title: item.name },
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      job.emergencyDegree == 1
                        ? _c(
                            "span",
                            { staticClass: "job-detail-tag job-tag-danger" },
                            [_vm._v("紧急")]
                          )
                        : _vm._e(),
                      job.difficultDegree == 1
                        ? _c(
                            "span",
                            { staticClass: "job-detail-tag job-tag-danger" },
                            [_vm._v("较难")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "job-item-body" }, [
                _c("div", { staticClass: "job-intro" }, [
                  _c("div", { staticClass: "job-info" }, [
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v(
                        _vm._s(
                          job.minSalary == 0 && job.maxSalary == 0
                            ? "面议"
                            : job.minSalary + "K-" + job.maxSalary + "K"
                        )
                      ),
                    ]),
                    job.minYearOfExperience == 0
                      ? _c("span", { staticClass: "job-info-item" }, [
                          _vm._v("经验不限"),
                        ])
                      : _c("span", { staticClass: "job-info-item" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterYearOfExperience")(
                                job.minYearOfExperience
                              )
                            ) + "经验"
                          ),
                        ]),
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v(_vm._s(_vm._f("degreeFilter")(job.minDegree))),
                    ]),
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v("需 " + _vm._s(job.recruitingCount) + " 人"),
                    ]),
                  ]),
                  _c("div", { staticClass: "job-promise" }, [
                    _vm._v("\n                  佣金\n                  "),
                    job.commissionType == "0"
                      ? _c(
                          "span",
                          { staticClass: "text-orange job-commission-value" },
                          [
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  job.minSalary == 0 && job.maxSalary == 0
                                    ? `按比例(${job.commissionValue}%)`
                                    : (
                                        (job.minSalary *
                                          12 *
                                          job.commissionValue) /
                                        100
                                      ).toFixed(2) +
                                        "K - " +
                                        (
                                          (job.maxSalary *
                                            12 *
                                            job.commissionValue) /
                                          100
                                        ).toFixed(2) +
                                        "K(" +
                                        job.commissionValue +
                                        "%)"
                                ) +
                                "\n                  "
                            ),
                          ]
                        )
                      : _vm._e(),
                    job.commissionType == "1"
                      ? _c(
                          "span",
                          { staticClass: "text-orange job-commission-value" },
                          [
                            _vm._v(
                              "\n                      " +
                                _vm._s(job.commissionValue + "K") +
                                "\n                  "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n                  保证期\n                  "),
                    _c(
                      "span",
                      { staticClass: "text-orange job-guarantee-period" },
                      [_vm._v(_vm._s(job.guaranteePeriod))]
                    ),
                    _vm._v("\n                  个月\n              "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "job-status" },
                  [
                    _c("avatar", {
                      staticClass: "user-avatar",
                      attrs: {
                        size: "sm",
                        src: job.recruiter.avatarUrl,
                        userId: job.ownerId,
                        enableCard: true,
                        enableLink: true,
                        shareData: _vm.getShareData(job),
                      },
                    }),
                    _c("span", { staticClass: "job-date" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm._f("createDateFilter")(job.created)) +
                          " 发布 / " +
                          _vm._s(_vm._f("dateFilter")(job.updated)) +
                          " 更新\n              "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "job-grab-btn-wrapper" },
                  [
                    job.status === 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "job-grab-btn",
                            attrs: {
                              icon: "el-icon-time",
                              type: "primary",
                              loading: job.grabing,
                              disabled: job.isMyOrder || job.isMyJob,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.grabOrder(job)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  !job.isMyOrder && !job.isMyJob
                                    ? "抢单"
                                    : "已抢"
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          })
        : _c("div", { staticClass: "empty-list" }, [
            _c("span", { staticClass: "empty-img" }),
            _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
          ]),
      _vm.total > _vm.pager.take
        ? _c(
            "el-pagination",
            {
              staticClass: "job-list-pagination",
              attrs: {
                "current-page": _vm.pager.current,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.pager.take,
                layout: "prev, pager, next, slot, total, sizes",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n        前往\n        "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n        页\n      "),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handlePagerJump(_vm.pagerJump)
                      },
                    },
                  },
                  [_vm._v("\n        跳转\n      ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }