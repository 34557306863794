<template>
    <div class="company-container">
        <div class="company-top" v-loading="loading">
            <div class="company-top-l">
                <div class="title">{{companyInfo.shortName}}
                    <span class="tag">已认证</span>
                </div>
                <div class="sub-title" v-text="companyInfo.name">赛诺菲国际医药集团</div>
                <div class="content" v-text="companyInfo.description || '暂无'">
                </div>
            </div>
            <div class="company-top-r">
                <!-- <el-button type="primary"
                    v-if="isAdministrator"
                    @click="handleEditCustomer"
                    >修改信息</el-button> -->
            </div>
        </div>

        <div class="company-main">
            <div class="company-l"  v-loading="listLoading">
                <div class="title-bar" style="margin-bottom: 20px;">
                    招聘岗位
                </div>
                <customer-job-list
                    ref="customerJobList"
                    :jobList="dataList"
                    :total="dataListTotal"
                    @update-list="getNormalJobList(true)"
                ></customer-job-list>
            </div>
            <div class="company-r"  v-loading="loading">
                <div class="title-bar">
                    公司信息
                </div>

                <div class="info-item" style="margin-top: 20px;">
                    <i class="el-icon-price-tag customer-info-icon" title="行业"></i>
                    <span v-text="companyInfo.industryText || '无'">互联网/电子商务</span>
                </div>
                <div class="info-item">
                    <i class="el-icon-user customer-info-icon" title="规模"></i>
                    <span v-text="formatScale(companyInfo.companyScale)">50-150人 </span>
                </div>
                <div class="info-item">
                    <i class="el-icon-location-outline customer-info-icon" title="地址"></i>
                    <span v-text="companyInfo.address || '暂无公司地址'">深圳南山区科技园高新技术产业园R1-B座2楼202</span>
                </div>
                <div class="info-item">
                    <i class="el-icon-office-building customer-info-icon" title="网址"></i>
                    <span v-text="companyInfo.webSite || '暂无公司网址'">暂无公司网址 </span>
                </div>
            </div>
        </div>

        <!-- <edit-customer-dialog
            ref="editCustomerDialog"
            @edit-callback="handleEditCallback"
        /> -->
    </div>
</template>

<script>
import CustomerService from '#/hr/js/service/customerService.js';
import JobService from '#/hr/js/service/jobService.js';
import CustomerJobList from './component/customer-job-list.vue';
// import EditCustomerDialog from './component/edit-customer-dialog.vue';

export default {
    name: 'HrFirm',
	components: {
        CustomerJobList,
        // EditCustomerDialog,
	},
	data() {
		return {
            dataList: [],
            dataListTotal: 0,
            companyInfo: {},
            loading: false,

            params: {
                customerId: "",
                departmentId: 0,
                status: 1,
            },

            listLoading: false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isAdministrator() {
            return this.userInfo.isAdministrator;
        },
        customerId() {
            return this.$route.query.customerId == 'undefined' ? null: this.$route.query.customerId;
        },
    },
    created() {
    },
	mounted() {
		// this.getNormalJobList(false);
        if(this.userInfo.firmId) {
            this.getFirmDetail();
            this.getNormalJobList(false);
        }
	},
    watch: {
        'userInfo.firmId': function(val) {
            if(val) {
                this.getFirmDetail();
                this.getNormalJobList(false);
            }
        }
    },
	methods: {
        getNormalJobList(isSilent) {
            this.listLoading = true;
            let params = Object.assign({}, this.params),
                pager = this.$refs.customerJobList.pager;

            if(!isSilent) {
                pager.current = 1;
            }
            params.start = (pager.current - 1) * pager.take;
            params.take = pager.take;
            params.firmId = this.customerId;

            JobService
            .companyJobList(params)
            .then(res => {
                this.listLoading = false;
                this.totalRecruitingCount = res.totalRecruitingCount;
                this.totalPausedCount = res.totalPausedCount;
                this.totalFinishedCount = res.totalFinishedCount;
                this.dataListTotal = res.total;
                this.dataList = res.list;
                if(isSilent) {
                    this.scrollToList();
                }
            }).catch(err => {
                this.listLoading = false;
            })
        },
        scrollToList() {
            this.$nextTick(() => {
                // document.documentElement.scrollTop = document.querySelector(".customer-list-wrapper").offsetTop;
            })
        },
        getFirmDetail() {
            this.loading = true;
            CustomerService
            .hrFirmDetail(this.customerId)
            .then(res => {
                console.log(res)
                this.companyInfo = res || {};
            })
            .finally(() => {
                this.loading = false;
            }); 
        },
        formatScale(scale) {
            const memberCountMap = {
                1: "少于50人",
                2: "50-150人",
                3: "150-500人",
                4: "500人以上",
            };
            
            return memberCountMap[+scale] || '未知';
        },
        handleEditCustomer() {
            const params = {
                industryId: this.companyInfo.industry == 0 ? '': String(this.companyInfo.industry),
                companyScale: this.companyInfo.companyScale == 0 ? '': String(this.companyInfo.companyScale),
                webSite: this.companyInfo.webSite,
                address: this.companyInfo.address,
                description: this.companyInfo.description,
                firmId: this.companyInfo.id,
            };
            this.$refs.editCustomerDialog.show(params);
        },

        handleEditCallback() {
            this.getFirmDetail();
        },
    },
}
</script>

<style lang="scss" scoped>
.company-container{
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    padding: 20px 20px 30px;
    // display: flex;
    // justify-content: space-between;
    .company-top{
        background: #FFF;
        border-radius: 8px;
        padding: 30px;
        box-sizing: border-box;
        overflow: hidden;
        margin-bottom: 20px;
        display: flex;
        .company-top-l{
            flex: 1;
            max-width: 1200px;
            .title{
                font-size: 24px;
                color: #000;
                line-height: 36px;
                .tag{
                    display: inline-block;
                    font-size: 12px;
                    color: #39BD9E;
                    line-height: 16px;
                    border: 1px solid #39BD9E;
                    padding: 0 6px;
                    border-radius: 9px;
                    margin-left: 10px;
                }
            }
            .sub-title{
                font-size: 14px;
                color: #666666;
                line-height: 42px;
            }
            .content{
                // width: 66%;
                margin-top: 6px;
                font-size: 14px;
                color: #999999;
                line-height: 24px;
                word-break: break-all;
            }

        }
        .company-top-r{
            width: 470px;
        }
        
    }

    .company-main{
        display: flex;
        .title-bar{
            font-size: 16px;
            font-weight: bold;
            color: #4A4A4A;
            position: relative;
            &::before{
                display: inline-block;
                content: '';
                width: 8px;
                height: 22px;
                background: $primary;
                position: absolute;
                left: -20px;
                top: -2px;

            }
        }
        .company-l{
            flex: 1;
            background: #FFF;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 20px;
        }
        .company-r{
            width: 500px;
            margin-left: 20px;
            background: #FFF;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 20px;
            .info-item{
                padding: 8px 0;
                line-height: 22px;
                .customer-info-icon {
                    color: #ccc;
                    font-size: 16px;
                }
                >span{
                    font-size: 14px;
                    color: #666666;
                    margin-left: 6px;
                }
            }
            
        }
    }


}
</style>