export default {
  account: {
    uerInfo: '/account/user-info',
    validateMobile:'/account/validate-mobile',  // 获取验证码
    register: '/account/register',
    agentConfig: '/wecom/corp/agent-config',
    loginedUserInfo: '/openapi/Account/LoginedUserInfo',
  },
  
  // 职位
  job: {
    firmJobCategories: '/openapi/JobCategory/FirmJobCategories',
    createHRJob: '/openapi/Job/CreateHRJob',
    updateJob: '/openapi/Job/UpdateJob',
    updateHRJobDescription: '/openapi/Job/UpdateHRJobDescription',
    jobList: '/openapi/Job/JobList',
    companyJobList: '/openapi/Job/CompanyJobList',
    applyJobList: '/openapi/Job/JobApplyList',
    getJobStatisticInfo: '/openapi/Job/BatchGetJobStatisticInfo', // HR直招职位推面o数据
    jobDetail: '/openapi/Job/%p/Detail',
    jobApplyDetail: '/openapi/Job/JobApplyDetail/%p',
    myRecentJobs: '/openapi/Job/MyRecentJobs',
    jobFeeds: '/openapi/Job/%p/Feeds',
    jobRecommendedCandidates: "/openapi/Job/RecommendedCandidates/%p",
  },

  // 推荐
  recommendation: {
    currentStatusStatistics: '/openapi/Recommendation/CurrentStatusStatistics',
    myRecentJobs: '/openapi/Job/MyRecentJobs',
    recommendSearchGroupedItems: '/openapi/Recommendation/GetRecommendationSearchGroupedItems',
    searchRecommendations: '/openapi/Recommendation/SearchRecommendations',

    acceptOrReject: '/openapi/RecommendationFlow/AcceptOrReject', // 接受或拒绝推荐
    stage: '/openapi/RecommendationFlow/Stage', // 进入面试/offer/入职/离职 流程操作
    obsolete: '/openapi/RecommendationFlow/Obsolete', // 淘汰
    toConfirmInterview: '/openapi/RecommendationFlow/ToConfirmInterview', // 确认面试
    interview: '/openapi/RecommendationFlow/Interview', // 安排面试
    interviewCancel: '/openapi/RecommendationFlow/Interview/Cancel', // 取消面试
    interviewFeedback: '/openapi/RecommendationFlow/Interview/Feedback', // 反馈面试结果
    offeringStage: '/openapi/RecommendationFlow/OfferingStage',
    entryOffering: "/openapi/RecommendationFlow/EntryOffering", // 进入Offering
    recover: '/openapi/RecommendationFlow/%p/Recover', // 恢复
    hrRecommendResumeDetail: '/openapi/Candidate/%p/HRRecommendResumeDetail', // HR推荐详情
  },

  //   客户
  customer: {
    hrFirmDetail: '/openapi/FirmManager/FirmDetail/%p',
  },
  
};
