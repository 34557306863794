import urls from '#/hr/js/config/api.js';
const { customer } = urls;

export default {
    // HR审批职位
    hrFirmDetail(firmId, params = {}) {
        return window._request({
            url: customer.hrFirmDetail.replace(/%p/, firmId),
            method: "GET",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
}